<template>
  <div class="ReferenceOperatorWorkMain">
    <div class="ReferenceOperatorWorkButtonWrapper">
      <v-btn color="#e21a1a" v-on:click="takeOrderReservation" depressed :loading="this.isReservationProcess">
        <v-icon color="white" left>mdi-arrow-right-bottom</v-icon>
        <span class="ReferenceOperatorWorkButtonText">ВЗЯТЬ В РАБОТУ</span>
      </v-btn>
    </div>
    <v-dialog v-model="dialog" max-width="1280" persistent>
      <v-card v-if="this.isShowWindow" class="file-upload-card" outlined>
        <v-form ref="productForm" @submit.prevent="checkInput">
          <v-card-title class="pb-4">{{ this.getReferenceName }}</v-card-title>
          <v-container class="OperatorWorkContainer">
            <v-row no-gutters class="RowField" v-for="item in getReferenceFields" :key="item.id">
              <v-col>
                <v-card-title v-if="item.name !== 'Токен'" class="VCardTitle">{{ item.name }}</v-card-title>
                <v-text-field
                  v-if="item.type === 'fullname'"
                  class="custom-label-color"
                  :rules="rules.wordForm"
                  outlined
                  :label="editOrder[item.in]"
                  v-model="editOrder[item.out]"
                ></v-text-field>
                <v-autocomplete
                  v-if="item.type === 'departStation'"
                  outlined
                  v-bind:label="getStationName(editOrder.reference[item.in])"
                  v-model="editOrder.reference[item.out]"
                  :items="stationList()"
                  item-text="text"
                  item-value="text"
                  class="custom-label-color"
                ></v-autocomplete>
                <v-autocomplete
                  v-if="item.type === 'arrivalStation'"
                  outlined
                  v-bind:label="getStationName(editOrder.reference[item.in])"
                  v-model="editOrder.reference[item.out]"
                  :items="stationList()"
                  item-text="text"
                  item-value="text"
                  class="custom-label-color"
                ></v-autocomplete>
                <v-text-field
                  v-if="item.type === 'trainNumber' && editOrder.referenceType !== 5"
                  outlined
                  v-on:keyup="showInUpperCase"
                  :label="editOrder.reference[item.in]"
                  v-model="editOrder.reference[item.out]"
                  :rules="rules.checkTrainNumber"
                  class="custom-label-color"
                ></v-text-field>
                <v-text-field
                  v-if="item.type === 'trainNumber' && editOrder.referenceType === 5"
                  :label="editOrder.reference[item.in]"
                  :rules="rules.carFormFourSymbols"
                  outlined
                  v-on:keyup="showInUpperCase"
                  v-model="editOrder.reference[item.out]"
                  hide-spin-buttons
                  min="0"
                ></v-text-field>
                <v-menu
                  v-if="item.type === 'departDate'"
                  v-model="datePickerMenu_1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="visibleDateFromDepartDate"
                      :label="visibleDateLabelDepart"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      readonly
                      prepend-icon="mdi-calendar"
                      class="custom-label-color"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    :first-day-of-week="1"
                    :weekday-format="getDay"
                    color="#e21a1a"
                    v-model="editOrder.reference[item.out]"
                    @input="datePickerMenu_1 = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  v-if="item.type === 'departTime'"
                  :label="editOrder.reference[item.in]"
                  :rules="rules.notEmpty.concat(rules.timeForm)"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  error
                ></v-text-field>
                <v-select
                  v-if="item.type === 'trainType' && editOrder.reference.hasOwnProperty([item.in])"
                  :items="trainList()"
                  :label="getTrainName(editOrder.reference[item.in])"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  class="custom-label-color"
                ></v-select>
                <v-select
                  v-if="item.type === 'trainType' && !editOrder.reference.hasOwnProperty([item.in])"
                  :items="trainList()"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  :rules="rules.notEmpty"
                  error
                ></v-select>
                <v-select
                  v-if="item.type === 'carType' && editOrder.reference.hasOwnProperty([item.in])"
                  :items="carList()"
                  v-bind:label="getCarName(editOrder.reference[item.in])"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  class="custom-label-color"
                ></v-select>
                <v-select
                  v-if="item.type === 'carType' && !editOrder.reference.hasOwnProperty([item.in])"
                  :items="carList()"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  :rules="rules.notEmpty"
                  error
                ></v-select>
                <v-select
                  v-if="item.type === 'tariffType' && editOrder.reference.hasOwnProperty([item.in])"
                  :items="tariffList()"
                  :label="editOrder.reference[item.in]"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  class="custom-label-color"
                ></v-select>
                <v-select
                  v-if="item.type === 'tariffType' && !editOrder.reference.hasOwnProperty([item.in])"
                  :items="tariffList()"
                  :label="editOrder.reference[item.in]"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  :rules="rules.notEmpty"
                  class="custom-label-color"
                  error
                ></v-select>
                <v-text-field
                  v-if="item.type === 'fullPrice'"
                  :rules="rules.notEmpty.concat(rules.priceSymbols)"
                  :label="getExpressFullPrice"
                  type="number"
                  hide-spin-buttons
                  outlined
                  v-model="editOrder.reference[item.out]"
                  class="custom-label-color"
                  min="0"
                  error
                ></v-text-field>
                <v-text-field
                  v-if="item.type === 'ticketPrice'"
                  :rules="rules.notEmpty.concat(rules.priceSymbols)"
                  :label="getExpressTicketPrice"
                  type="number"
                  hide-spin-buttons
                  outlined
                  v-model="editOrder.reference[item.out]"
                  class="custom-label-color"
                  min="0"
                  error
                ></v-text-field>
                <v-text-field
                  v-if="item.type === 'ReservePrice'"
                  :rules="rules.notEmpty.concat(rules.priceSymbols)"
                  :label="getExpressReservePrice"
                  type="number"
                  hide-spin-buttons
                  outlined
                  v-model="editOrder.reference[item.out]"
                  class="custom-label-color"
                  min="0"
                  error
                ></v-text-field>
                <v-text-field
                  v-if="item.type === 'SerivePrice'"
                  :rules="rules.notEmpty.concat(rules.priceSymbols)"
                  :label="getExpressServicePrice"
                  type="number"
                  hide-spin-buttons
                  outlined
                  v-model="editOrder.reference[item.out]"
                  class="custom-label-color"
                  min="0"
                  error
                ></v-text-field>
                <v-text-field
                  v-if="item.type === 'CommisionPrice'"
                  type="number"
                  hide-spin-buttons
                  outlined
                  :rules="rules.notEmpty.concat(rules.priceSymbols)"
                  v-model="editOrder.reference[item.out]"
                  min="0"
                  error
                ></v-text-field>
                <v-select
                  v-if="item.type === 'tickerReturn'"
                  :items="returnList()"
                  :rules="rules.notEmpty.concat(rules.wordForm)"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  error
                ></v-select>
                <v-text-field
                  v-if="item.type === 'mail'"
                  :rules="rules.emailForm"
                  outlined
                  :label="editOrder[item.in]"
                  v-model="editOrder[item.out]"
                  class="custom-label-color"
                ></v-text-field>
                <v-text-field
                  v-if="item.type === 'phone'"
                  outlined
                  :rules="rules.numberForm"
                  :label="editOrder[item.in]"
                  v-model="editOrder[item.out]"
                  class="custom-label-color"
                  type="number"
                  hide-spin-buttons
                  min="0"
                ></v-text-field>
                <v-textarea v-if="item.type === 'comment'" auto-grow readonly disabled outlined v-model="editOrder[item.in]"></v-textarea>
                <v-text-field
                  v-if="item.type === 'ticketNumber' && editOrder.reference[item.in].length > 0"
                  outlined
                  :label="editOrder.reference[item.in]"
                  v-model="editOrder.reference[item.out]"
                  class="custom-label-color"
                ></v-text-field>
                <v-text-field
                  v-if="item.type === 'ticketNumber' && editOrder.reference[item.in] == ''"
                  outlined
                  :rules="rules.notEmpty.concat(rules.ticketForm)"
                  v-model="editOrder.reference[item.out]"
                  class="custom-label-color"
                  error
                ></v-text-field>
                <v-text-field
                  v-if="item.type === 'PassengerDocument'"
                  error
                  :rules="rules.notEmpty"
                  outlined
                  v-model="editOrder.reference[item.out]"
                ></v-text-field>
                <v-text-field
                  v-if="item.type === 'carNumber'"
                  :rules="rules.notEmpty.concat(rules.carForm)"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  type="number"
                  hide-spin-buttons
                  min="0"
                  error
                ></v-text-field>
                <v-text-field
                  v-if="item.type === 'seatNumber'"
                  :rules="rules.notEmpty.concat(rules.seatAvailableForm)"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  type="number"
                  min="0"
                  hide-spin-buttons
                  error
                ></v-text-field>
                <v-text-field
                  v-if="item.type === 'seatAviable'"
                  :label="editOrder.reference[item.in]"
                  :rules="rules.notEmpty.concat(rules.seatAvailableForm)"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  type="number"
                  hide-spin-buttons
                  min="0"
                  error
                ></v-text-field>
                <v-select
                  v-if="item.type === 'seatType'"
                  :items="seatList()"
                  :label="getSeatName(editOrder.reference[item.in])"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  class="custom-label-color"
                ></v-select>
                <v-autocomplete
                  v-if="item.type === 'changeStation'"
                  :disabled="editOrder.reference.directMessage"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  :items="stationList()"
                  item-text="text"
                  item-value="text"
                ></v-autocomplete>
                <v-text-field
                  v-if="item.type === 'directMessage'"
                  :rules="rules.notEmpty.concat(rules.wordForm)"
                  outlined
                  v-model="editOrder.reference[item.out]"
                ></v-text-field>
                <v-menu
                  v-if="item.type === 'arrivalDate'"
                  v-model="datePickerMenu_2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :label="visibleLableArrivalDate"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      readonly
                      v-model="visibleDateFromArrivalDate"
                      class="custom-label-color"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    :first-day-of-week="1"
                    :weekday-format="getDay"
                    color="#e21a1a"
                    v-model="editOrder.reference[item.out]"
                    @input="datePickerMenu_2 = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  v-if="item.type === 'arrivalTime'"
                  :rules="rules.notEmpty.concat(rules.timeForm)"
                  outlined
                  error
                  v-model="editOrder.reference[item.out]"
                ></v-text-field>

                <v-menu
                  v-if="item.type === 'arrivalDateActually'"
                  v-model="datePickerMenu_3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :rules="notEmptyRules" error readonly v-bind="attrs" v-on="on" outlined v-model="visibleDateFromActually"></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    :first-day-of-week="1"
                    :weekday-format="getDay"
                    color="#e21a1a"
                    v-model="editOrder.reference[item.out]"
                    @input="datePickerMenu_3 = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  v-if="item.type === 'arrivalTimeActually'"
                  :rules="rules.notEmpty.concat(rules.timeForm)"
                  outlined
                  v-model="editOrder.reference[item.out]"
                  error
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="ml-auto" cols="6">
                <v-card v-if="choosenCert !== null" outlined>
                  <v-card-title>Выбраный сертификат</v-card-title>
                  <v-card-text>
                    <div>{{ showCertOwner(choosenCert, 'Владелец') }}</div>
                    <div>Компания: {{ showCertOwner(choosenCert, 'Компания') }}</div>
                    <div>Удостоверяющий центр: {{ showCertOwner(choosenCert, 'Удостоверяющий центр') }}</div>
                    <div>Город: {{ showCertOwner(choosenCert, 'Город') }}</div>
                    <div>Email: {{ showCertOwner(choosenCert, 'Email') }}</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions class="mb-2 d-flex">
            <!-- <v-btn v-if="editOrder.currentState !== 100" text class="mr-auto mx-2 text-none my-1" color="primary" @click="openConfirmDialog"
              >ОТМЕНИТЬ ЗАЯВКУ</v-btn
            > -->
            <!-- <v-btn @click="showCertSelectionDialog = true">Выбрать сертификат</v-btn> -->
            <v-btn
              :loading="isSendPdfLoading"
              :disabled="this.isSendPdfDisabled || choosenCert === null"
              type="submit"
              depressed
              color="#e21a1a"
              class="white--text my-1 pa-2 ml-auto"
              v-on:click="confirmAndSendPdfClick"
            >
              ПОДПИСАТЬ PDF И ОТПРАВИТЬ
            </v-btn>
            <v-btn
              :loading="isPdfLoading"
              :disabled="isValidate"
              type="submit"
              depressed
              color="#e21a1a"
              class="white--text my-1"
              v-on:click="showCertSelectionDialog = true"
            >
              ПОЛУЧИТЬ PDF И ПРОВЕРИТЬ
            </v-btn>
            <v-btn text class="mx-2 text-none my-1" color="grey darken-3" @click="closeDialog">ОТМЕНА</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cryptoProErrorDialog" max-width="1024" persistent>
      <v-card class="file-upload-card pa-4" outlined>
        <div>
          Не удалось получить сертификат из CryptoPro. Проверьте, что плагин CryptoPro установлен и сертификат в наличии.
        </div>
        <v-btn type="submit" depressed color="#e21a1a" class="white--text mr-3 text-RussianRail" v-on:click="closeCryptoProErrorDialog">Закрыть</v-btn>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="cancelReference" max-width="1024" persistent>
      <v-card class="file-upload-card pa-4" outlined>
        <div>
          Вы действительно хотите отменить заявку? <br />
          Обязанность возврата денег и связь с клиентом ложится на оператора
        </div>
        <v-text-field outlined :label="editOrder.mail" v-model="cancelEmail"></v-text-field>
        <v-text-field outlined label="Причина" :rules="rules.notEmpty" v-model="cancelReason"></v-text-field>
        <div v-if="this.isCancelError">Не удалось отменить заявку: {{ this.cancelMessage }}</div>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn type="submit" depressed class="mr-3 text-RussianRail" v-on:click="closeCancelDialog">Вернуться к заполнению</v-btn>
          <v-btn
            type="submit"
            depressed
            color="#e21a1a"
            :loading="this.isCancelLoading"
            class="white--text mr-3 text-RussianRail"
            v-on:click="confirmCancelReference"
            >Отменить заявку</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="showCertSelectionDialog" max-width="600">
      <v-card>
        <v-card-title>Выбор сертификата</v-card-title>
        <v-card-text>
          <div v-if="this.isCertListData">
            <v-card v-for="cert in this.certsList" :key="cert.thumbprint" outlined @click="confirmAndGetPdfClick(cert)" class="mt-2">
              <v-card-title>{{ showCertOwner(cert, 'Владелец') }}</v-card-title>
              <v-card-text>
                <div>Компания: {{ showCertOwner(cert, 'Компания') }}</div>
                <div>Удостоверяющий центр: {{ showCertOwner(cert, 'Удостоверяющий центр') }}</div>
                <div>Город: {{ showCertOwner(cert, 'Город') }}</div>
                <div>Email: {{ showCertOwner(cert, 'Email') }}</div>
              </v-card-text>
            </v-card>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="doCertsList" depressed color="#e21a1a" class="white--text my-1">Обновить сертификаты</v-btn>
            <v-btn @click="showCertSelectionDialog = false" text class="mx-2 text-none my-1" color="grey darken-3">ЗАКРЫТЬ</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import cadesplugin from 'crypto-pro-cadesplugin';
import moment from 'moment';
export default {
  props: {
    order: {
      required: true
    }
  },
  data() {
    return {
      showCertSelectionDialog: false,
      isShowCertSelectionDialog: false,
      editOrder: this.order,
      dialog: false,
      validate: true,
      cryptoProErrorDialog: false,
      loading: false,
      datePickerMenu_1: false,
      datePickerMenu_2: false,
      datePickerMenu_3: false,
      notEmptyRules: [v => !!v || 0 || 'Обязательное поле'],
      certsApi: null,
      certsList: null,
      cert: null,
      isShowWindow: false,
      isReservationProcess: false,
      returnCheckBox: false,
      returnTicket: false,
      cancelReference: false,
      cancelReason: '',
      cancelEmail: '',
      certPosition: 0,
      choosenCert: null,
      rules: {
        emailForm: [
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || value.trim() == '' || 'Неверный формат';
          }
        ],
        numberForm: [v => (v.length > 10 && v.length < 12) || v.trim() == '' || 'Проверьте правильность указанного номера'],
        checkTrainNumber: [
          value => {
            const pattern = /^((\d{3})+([А-Я]))$/;
            return pattern.test(value) || value.trim() == '' || 'Неверный формат (Пример: 001А)';
          }
        ],
        wordForm: [
          value => {
            const pattern = /[ЁёА-я,A-Za-z .-]+$/;
            return pattern.test(value) || value.trim() == '' || 'Неверный формат';
          }
        ],
        timeForm: [
          value => {
            const pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
            return pattern.test(value) || 'Неверный формат времени (ЧЧ:ММ)';
          }
        ],
        notEmpty: [v => !!v || 0 || 'Обязательное поле'],
        ticketForm: [v => (v.length >= 13 && v.length <= 14) || 'Проверьте правильность указанного номера. 13 или 14 цифр'],
        carForm: [v => (v.length >= 1 && v.length <= 2) || 'Проверьте правильность указанного номера'],
        seatAvailableForm: [v => (v.length >= 0 && v.length <= 3) || 'Проверьте правильность указанного числа'],
        carFormFourSymbols: [
          value => {
            const pattern1 = /^((\d{3})+([А-я])?)$/;
            const pattern2 = /^((\d{4}))$/;
            return pattern1.test(value) || pattern2.test(value) || value.trim() == '' || 'Неверный формат';
          }
        ],
        priceSymbols: [
          value => {
            const pattern = /^\d+(?:\.\d+)?$/;
            return (pattern.test(value) && value !== 0) || 'Неверный формат';
          }
        ]
      }
    };
  },
  computed: {
    showPresentDate() {
      return moment()
        .format()
        .substring(0, 10);
    },
    showTrainType() {
      return this.defineTrainType(this.editOrder.reference.modifyTrainNumber);
    },
    visibleLableArrivalDate() {
      return this.changeDateForm(this.editOrder.reference.departDate);
    },
    visibleDateFromActually() {
      return this.changeDateForm(this.editOrder.reference.modifyArrivalDateActually);
    },
    visibleDateFromArrivalDate() {
      return this.changeDateForm(this.editOrder.reference.modifyArrivalDate);
    },
    visibleDateFromDepartDate() {
      return this.changeDateForm(this.editOrder.reference.modifyDepartDate);
    },
    visibleDateLabelDepart() {
      return this.changeDateForm(this.editOrder.reference.departDate);
    },
    isValidate() {
      let result = true;
      if (this.editOrder.referenceType === 1) {
        if (
          (this.editOrder.reference.modifyDepartTime.length > 0 || (this.editOrder.reference.departTime && this.editOrder.reference.departTime.length > 0)) &&
          this.editOrder.reference.modifyTotalPrice.length > 0 &&
          this.editOrder.reference.modifyTicketPrice.length > 0 &&
          this.editOrder.reference.modifyReservePrice.length > 0 &&
          this.editOrder.reference.modifyCommissionPrice.length > 0 &&
          this.editOrder.reference.modifyServicePrice.length > 0
        )
          result = false;
      } else if (this.editOrder.referenceType === 2) {
        if (
          (this.editOrder.reference.modifyTicketNumber.length > 0 || this.editOrder.reference.ticketNumber.length > 0) &&
          this.editOrder.reference.modifyTariffType.length > 0 &&
          (this.editOrder.reference.modifyDepartTime.length > 0 || (this.editOrder.reference.departTime && this.editOrder.reference.departTime.length > 0)) &&
          this.editOrder.reference.modifyPassengerDocument.length > 0 &&
          this.editOrder.reference.modifyTrainType.length > 0 &&
          this.editOrder.reference.modifyCarNumber.length > 0 &&
          this.editOrder.reference.modifyCarType.length > 0 &&
          this.editOrder.reference.modifySeatNumber.length > 0 &&
          this.editOrder.reference.modifyTotalPrice.length > 0 &&
          this.editOrder.reference.modifyTicketPrice.length > 0 &&
          this.editOrder.reference.modifyReservePrice.length > 0 &&
          this.editOrder.reference.modifyCommissionPrice.length > 0 &&
          this.editOrder.reference.modifyTicketReturn.length > 0 &&
          this.editOrder.reference.modifyServicePrice.length > 0
        )
          result = false;
      } else if (this.editOrder.referenceType === 3) {
        if (
          (this.editOrder.reference.modifyDepartTime.length > 0 || (this.editOrder.reference.departTime && this.editOrder.reference.departTime.length > 0)) &&
          this.editOrder.reference.modifyTrainType.length > 0 &&
          this.editOrder.reference.modifySeatAvailable.length > 0
        )
          result = false;
      } else if (this.editOrder.referenceType === 4) {
        result = false;
      } else if (this.editOrder.referenceType === 5) {
        if (
          this.editOrder.reference.modifyArrivalDateActually.length > 0 &&
          this.editOrder.reference.modifyArrivalTime.length > 0 &&
          this.editOrder.reference.modifyArrivalTimeActually.length > 0
        )
          result = false;
      }
      return result;
    },
    isSendPdfDisabled() {
      return this.pdfData === undefined || this.pdfData === null;
    },
    getExpressFullPrice() {
      let returnValue = '';
      if (
        this.editOrder &&
        this.editOrder.reference &&
        this.editOrder.reference.expressTravelPrice &&
        this.editOrder.reference.expressTravelPrice.tarif !== undefined &&
        this.editOrder.reference.expressTravelPrice.tarif !== null
      ) {
        returnValue = this.editOrder.reference.expressTravelPrice.tarif;
      }
      return returnValue;
    },
    getExpressTicketPrice() {
      let returnValue = '';
      if (
        this.editOrder &&
        this.editOrder.reference &&
        this.editOrder.reference.expressTravelPrice &&
        this.editOrder.reference.expressTravelPrice.tarifbilet !== undefined &&
        this.editOrder.reference.expressTravelPrice.tarifbilet !== null
      ) {
        returnValue = this.editOrder.reference.expressTravelPrice.tarifbilet;
      }
      return returnValue;
    },
    getExpressReservePrice() {
      let returnValue = '';
      if (
        this.editOrder &&
        this.editOrder.reference &&
        this.editOrder.reference.expressTravelPrice &&
        this.editOrder.reference.expressTravelPrice.tarifplac !== undefined &&
        this.editOrder.reference.expressTravelPrice.tarifplac !== null
      ) {
        returnValue = this.editOrder.reference.expressTravelPrice.tarifplac;
      }
      return returnValue;
    },
    getExpressServicePrice() {
      let returnValue = '';
      if (
        this.editOrder &&
        this.editOrder.reference &&
        this.editOrder.reference.expressTravelPrice &&
        this.editOrder.reference.expressTravelPrice.tarifsrv !== undefined &&
        this.editOrder.reference.expressTravelPrice.tarifsrv !== null
      ) {
        returnValue = this.editOrder.reference.expressTravelPrice.tarifsrv;
      }
      return returnValue;
    },
    getReferenceName() {
      let returnName = 'Справка';
      if (this.editOrder.referenceType === 1) {
        returnName = 'Справка о стоимости проезда';
      } else if (this.editOrder.referenceType === 2) {
        returnName = 'Справка о подтверждении ранее приобретенного (утерянного) проездного документа';
      } else if (this.editOrder.referenceType === 3) {
        returnName = 'Справка о наличии мест в поезде дальнего следования';
      } else if (this.editOrder.referenceType === 4) {
        returnName = 'Справка о проезде в поездах дальнего следования по кратчайшему маршруту следования (на момент обращения)';
      } else if (this.editOrder.referenceType === 5) {
        returnName = 'Справка об изменении графика движения поездов';
      }
      return returnName;
    },
    isShowDepartStation() {
      return (
        this.editOrder.referenceType === 1 || this.editOrder.referenceType === 2 || this.editOrder.referenceType === 3 || this.editOrder.referenceType === 4
      );
    },
    isShowArrivalStation() {
      return (
        this.editOrder.referenceType === 1 ||
        this.editOrder.referenceType === 2 ||
        this.editOrder.referenceType === 3 ||
        this.editOrder.referenceType === 4 ||
        this.editOrder.referenceType === 5
      );
    },
    isShowDepartDate() {
      return (
        this.editOrder.referenceType === 1 ||
        this.editOrder.referenceType === 2 ||
        this.editOrder.referenceType === 3 ||
        this.editOrder.referenceType === 4 ||
        this.editOrder.referenceType === 5
      );
    },
    isShowTariffType() {
      return this.editOrder.referenceType === 1 || this.editOrder.referenceType === 2;
    },
    isShowTrainNumber() {
      return (
        this.editOrder.referenceType === 1 || this.editOrder.referenceType === 2 || this.editOrder.referenceType === 3 || this.editOrder.referenceType === 5
      );
    },
    isShowTicketNumber() {
      return this.editOrder.referenceType === 2;
    },
    isShowCarType() {
      return this.editOrder.referenceType === 1 || this.editOrder.referenceType === 2 || this.editOrder.referenceType === 3;
    },
    isShowSeatType() {
      return this.editOrder.referenceType === 1 || this.editOrder.referenceType === 2 || this.editOrder.referenceType === 3;
    },
    isShowTicketPrice() {
      return this.editOrder.referenceType === 1 || this.editOrder.referenceType === 2;
    },
    isShowReservePrice() {
      return this.editOrder.referenceType === 1 || this.editOrder.referenceType === 2;
    },
    isShowServicePrice() {
      return this.editOrder.referenceType === 1 || this.editOrder.referenceType === 2;
    },
    isShowTotalPrice() {
      return this.editOrder.referenceType === 1 || this.editOrder.referenceType === 2;
    },
    isShowCommissionPrice() {
      return this.editOrder.referenceType === 2 || this.editOrder.referenceType === 1;
    },
    isShowCarNumber() {
      return this.editOrder.referenceType === 2;
    },
    isShowSeatNumber() {
      return this.editOrder.referenceType === 2;
    },
    isShowMilitaryNumber() {
      return this.editOrder.referenceType === 2;
    },
    isShowTicketReturn() {
      return this.editOrder.referenceType === 2;
    },
    isShowPassengerDocument() {
      return this.editOrder.referenceType === 2;
    },
    isShowArrivalDate() {
      return this.editOrder.referenceType === 5;
    },
    isShowArrivalDateActually() {
      return this.editOrder.referenceType === 5;
    },
    isShowInformationDate() {
      return (
        this.editOrder.referenceType === 1 ||
        this.editOrder.referenceType === 2 ||
        this.editOrder.referenceType === 3 ||
        this.editOrder.referenceType === 4 ||
        this.editOrder.referenceType === 5
      );
    },
    isShowSeatAvailable() {
      return this.editOrder.referenceType === 3;
    },
    isShowDirectMessage() {
      return this.editOrder.referenceType === 4;
    },
    isShowChangeStation() {
      return this.editOrder.referenceType === 4;
    },
    getReferenceFields() {
      const fields = ReferenceHelper.getFields();
      return { ...fields[this.editOrder.referenceType] };
    },
    isCertListData() {
      if (this.certsList !== undefined && this.certsList !== null && this.certsList.length > 0) {
        return true;
      }
      return false;
    },
    ...mapGetters('reference', ['isPdfLoading']),
    ...mapGetters('reference', ['isPdfError']),
    ...mapGetters('reference', ['pdfResult']),
    ...mapGetters('reference', ['pdfData']),
    ...mapGetters('reference', ['pdfMessage']),
    ...mapGetters('reference', ['isSendPdfLoading']),
    ...mapGetters('reference', ['isSendPdfError']),
    ...mapGetters('reference', ['sendPdfResult']),
    ...mapGetters('reference', ['sendPdfData']),
    ...mapGetters('reference', ['sendPdfMessage']),
    ...mapGetters('reference', ['isCancelLoading']),
    ...mapGetters('reference', ['isCancelError']),
    ...mapGetters('reference', ['isCancelComplete']),
    ...mapGetters('reference', ['cancelResult']),
    ...mapGetters('reference', ['cancelMessage'])
  },
  methods: {
    getSeatName: ReferenceHelper.getSeatName,
    getCarName: ReferenceHelper.getCarName,
    getTariffName: ReferenceHelper.getTariffName,
    getStationName: ReferenceHelper.getStationName,
    getTrainName: ReferenceHelper.getTrainName,
    defineTrainType(trainNumber) {
      let num = trainNumber.slice(0, 3);
      if ((num >= 1 && num <= 298) || (num >= 801 && num <= 898)) {
        return 'СКОРЫЙ';
      } else if ((num >= 301 && num <= 598) || (num >= 601 && num <= 698)) {
        return 'ПАССАЖИРСКИЙ';
      }
      if (num >= 701 && num <= 750) {
        return 'СКОРОСТНОЙ';
      }
      if ((num >= 751 && num <= 788) || (num >= 601 && num <= 898)) {
        return 'ВЫСОКОСКОРОСТНОЙ';
      } else return '';
    },
    changeDateForm(pickerDate) {
      if (pickerDate === '') return '';
      return pickerDate
        .split('-')
        .reverse()
        .join('.');
    },
    showInUpperCase() {
      this.editOrder.reference.modifyTrainNumber = this.editOrder.reference.modifyTrainNumber.toUpperCase();
    },
    getDay(date) {
      const daysOfWeek = ReferenceHelper.getDateList();
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    stationList() {
      return ReferenceHelper.getStationList();
    },
    tariffList() {
      return ReferenceHelper.getTariffTypeList();
    },
    carList() {
      return ReferenceHelper.getCarTypeList();
    },
    seatList() {
      return ReferenceHelper.getSeatTypeList();
    },
    checkInput() {
      return true;
    },
    trainList() {
      return ReferenceHelper.getTrainTypeList();
    },
    returnList() {
      return ReferenceHelper.getreturnList();
    },
    showCertOwner(cert, value) {
      const certInfo = [].concat(cert.friendlySubjectInfo(), cert.friendlyIssuerInfo());
      const friendlyValidPeriod = cert.friendlyValidPeriod();
      const {
        to: { ddmmyy, hhmmss }
      } = friendlyValidPeriod;
      if (value === 'Владелец') return `${certInfo.filter(el => el.value === value)[0].text}. Сертификат действителен до: ${ddmmyy} ${hhmmss}`;
      return certInfo.filter(el => el.value === value)[0].text;
    },
    async confirmCancelReference() {
      try {
        const data = { orderId: this.editOrder.id, message: this.cancelReason };
        if (this.cancelEmail !== undefined && this.cancelEmail !== null && this.cancelEmail.length > 0) {
          data.mail = this.cancelEmail;
        }
        await this.cancelOrder(data);
        this.closeCancelDialog();
        this.closeDialog();
      } catch (error) {
        console.log(error);
      }
    },
    async confirmAndGetPdfClick(cert) {
      if (this.$refs.productForm.validate()) {
        await this.confirmAndGetPdf({ order: this.editOrder, cert: cert });
        this.choosenCert = cert;
        this.downloadFileBase64(this.pdfData.pdf, this.pdfData.fileName);
        this.showCertSelectionDialog = false;
      }
    },
    async confirmAndSendPdfClick() {
      const fileSign = await this.certsApi.signBase64(this.choosenCert.thumbprint, this.pdfData.pdf, true);
      await this.confirmAndSendPdf({ order: this.editOrder, pdf: this.pdfData.pdf, sig: fileSign });
      this.fetchReferenceList().then(() => {});
      this.closeDialog();
    },
    downloadFile(file, filename) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([file]));
      link.setAttribute('download', filename);

      // a.download = "Image.png"; //File name Here
      // a.click(); //Downloaded file
      document.body.appendChild(link);
      link.click();
    },
    downloadFileBase64(fileBase64, filename) {
      const link = document.createElement('a');
      link.href = 'data:binary;base64,' + fileBase64;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
    closeDialog() {
      this.dialog = false;
      this.deletePdfData();
      this.editOrder = this.order;
      this.fetchReferenceList();
      this.clearOrderTimer();
    },
    closeCryptoProErrorDialog() {
      this.cryptoProErrorDialog = false;
    },
    closeCancelDialog() {
      this.cancelReference = false;
    },
    openConfirmDialog() {
      this.cancelReference = true;
    },
    async doCertsList() {
      this.getCertData();
      // const certsApi = await cadesplugin();
      // this.certsList = await certsApi.getCertsList();
    },
    async changeCertPosition(value) {
      if (this.certsList === undefined || this.certsList === null || this.certsList.length <= 0) {
        this.certPosition = 0;
        return;
      }
      const newCertPosition = this.certPosition + value;
      if (newCertPosition >= 0 && newCertPosition < this.certsList.length) {
        this.certPosition = newCertPosition;
        try {
          //   if (this.cert === undefined || this.cert === null) {
          //   }
          await this.getCertData();
        } catch (ex) {
          console.log(ex);
          this.cryptoProErrorDialog = true;
        }
      }
    },
    async getCertData() {
      const certsApi = await cadesplugin();
      this.certsApi = certsApi;
      const certsList = await certsApi.getCertsList();
      this.certsList = certsList;
      //this.downloadFile(this.pdfData);
      const selectedCert = await certsApi.getCert(certsList[this.certPosition].thumbprint);
      this.cert = {
        thumbprint: selectedCert.thumbprint,
        validFrom: selectedCert.validPeriod.from.slice(0, 10),
        validTo: selectedCert.validPeriod.to.slice(0, 10),
        subject: selectedCert.friendlySubjectInfo(),
        issuer: selectedCert.friendlyIssuerInfo()
      };
    },
    convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    },
    createEditingObject() {
      this.editOrder = JSON.parse(JSON.stringify(this.order));
      // this.editOrder = Object.assign({}, this.order);
    },
    async takeOrderReservation() {
      try {
        this.editOrder = JSON.parse(JSON.stringify(this.order));
        this.isReservationProcess = true;
        await this.takeOrder({ orderId: this.order.id });
        this.isShowWindow = true;
        this.dialog = true;
        await this.setOrderTimer(moment());
      } catch (ex) {
        this.isShowWindow = false;
        this.dialog = false;
        await this.clearOrderTimer();
      } finally {
        this.isReservationProcess = false;
      }
    },
    ...mapActions('reference', ['takeOrder']),
    ...mapActions('reference', ['confirmAndGetPdf']),
    ...mapActions('reference', ['confirmAndSendPdf']),
    ...mapActions('reference', ['fetchReferenceList']),
    ...mapActions('reference', ['addSignToPdf']),
    ...mapActions('reference', ['deletePdfData']),
    ...mapActions('reference', ['setOrderTimer']),
    ...mapActions('reference', ['clearOrderTimer']),
    ...mapActions('reference', ['cancelOrder'])
  },
  mounted() {
    this.createEditingObject();
  }
};
</script>
<style>
.RowField {
  padding-left: 15px;
}
.custom-label-color .v-label .theme--light.v-label {
  color: rgb(22, 216, 241);
  opacity: 1;
}
.OperatorWorkContainer > .RowField {
  /* flex: 1 1 33%; grow | shrink | basis */
  /* height: 100px; */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 30%;
}
.OperatorWorkContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.ButtonsGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}
.CancelButton {
  margin-right: auto;
}

.file-upload-card {
  font-family: RussianRail;
}

.OperatorWorkField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
}

.ReferenceOperatorWorkMain {
  font-weight: 400;
}

.ReferenceOperatorWorkButtonIcon {
  margin-right: 10px;
}

.ReferenceOperatorWorkButtonText {
  color: white;
}

.ReferenceOperatorWorkButtonCancelText {
  color: gray;
}

.ReferenceOperatorWorkFieldName {
  display: flex;
  justify-content: center;
  font-size: large;
  margin-bottom: 10px;
  padding: 0;
  color: black;
}

.ReferenceOperatorWorkBlockSpacer {
  margin-top: 20px;
}

.ReferenceOperatorWorkAlertBlock {
  margin-top: 20px;
  color: #c00000;
}

.ReferenceOperatorWorkPanelHeaderWarning {
  font-weight: bold;
  color: #ed7d31;
}

.ReferenceOperatorWorkPanelHeaderError {
  font-weight: bold;
  color: #c00000;
}

.ReferenceOperatorWorkHintText {
  color: #606060;
}

.ReferenceOperatorWorkPicker {
  border-radius: 5px;
  color: white;
  font-weight: 400;
  box-shadow: 0 2px 2px #8eaadb;
  border: solid 1px #2f5496;
  margin: 20px 0 10px 0;
  padding: 2px;
}

.ReferenceOperatorWorkButtonWrapper {
  border: solid 1px #e21a1a;
  border-radius: 6px;
  width: min-content;
}

.ReferenceOperatorWorkButtonCancelWrapper {
  border: solid 1px gray;
  border-radius: 6px;
  margin-left: 20px;
}

.ReferenceOperatorWorkFileText {
  color: black;
}

.ReferenceOperatorWorkCheckResult {
  display: flex;
  //justify-content: space-between;
  margin: 20px 0 0 0;
}

.ReferenceOperatorWorkProcessList {
  display: flex;
  margin: 0 40px 0 0;
}

.ReferenceOperatorWorkProcessListNumber {
  font-size: 42px;
  color: #2f5496;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListText {
  font-size: 14px;
  color: #8eaadb;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberTotal {
  font-size: 42px;
  color: black;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextTotal {
  font-size: 14px;
  color: #a6a6a6;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberWarning {
  font-size: 42px;
  color: #ed7d31;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextWarning {
  font-size: 14px;
  color: #f4b083;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberError {
  font-size: 42px;
  color: #c00000;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextError {
  font-size: 14px;
  color: #ff4b4b;
  margin-left: 16px;
}

.ReferenceOperatorWorkTableRowWarningFirst {
  background-color: #fbe4d5;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowWarningSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowErrorFirst {
  background-color: #ffc5c5;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowErrorSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableHeaderWarning {
  color: black;
  //font-size: small;
  font-weight: bold;
  //background-color: #8ab0d2;
  padding: 0 6px 0 6px;
  //border-radius: 5px;
  border-bottom: solid 1px #ed7d31;
}

.ReferenceOperatorWorkTableHeaderError {
  color: black;
  //font-size: small;
  font-weight: bold;
  //background-color: #8ab0d2;
  padding: 0 6px 0 6px;
  //border-radius: 5px;
  border-bottom: solid 1px #c00000;
}

.VCardTitle {
  font-family: FsRail;
  font-size: 0.8em !important;
  margin: 0;
  padding: 0;
}

v-col {
  padding: 0;
}
</style>
