<template>
  <div class="ReferenceOperatorListMain">
    <v-text-field label="Поиск" append-icon="mdi-magnify" outlined v-model="search"></v-text-field>
    <v-row class="px-2">
      <v-checkbox class="mr-2" v-model="isShowPaymentTrue" justijy-content-end :label="'Ожидание экспресса'"></v-checkbox>
      <v-checkbox class="mr-2" v-model="isShowWaitingOperatorWork" justijy-content-end :label="'Обработка оператором'"></v-checkbox>
      <v-checkbox class="mr-2" v-model="isShowOperatorWorkDone" justijy-content-end :label="'Отправка на почту'"></v-checkbox>
      <v-checkbox class="mr-2" v-model="isShowWorkDone" justijy-content-end :label="'Завершены'"></v-checkbox>
      <v-checkbox class="mr-2" v-model="isCancelReference" justijy-content-end :label="'Отменены'"></v-checkbox>
      <v-checkbox class="ml-auto" v-model="isWorkProgress" justijy-content-end :label="'Забронированы другими'"></v-checkbox>
    </v-row>
    <v-expansion-panels mb="3">
      <v-expansion-panel v-for="reference in filteredReferenceList.slice().reverse()" :key="reference.id">
        <v-expansion-panel-header :color="getColor(reference)">
          <v-row>
            <v-col cols="12" sm="6" xs="12" md="2" lg="1">
              <p class="ReferenceOperatorListTableHeader">Номер заказа</p>
              {{ reference.orderNumber }}
            </v-col>
            <v-col cols="12" sm="6" xs="12" md="2" lg="2">
              <p class="ReferenceOperatorListTableHeader">ФИО</p>
              {{ reference.fullName }}
            </v-col>
            <v-col cols="12" sm="6" xs="12" md="2" lg="2">
              <p class="ReferenceOperatorListTableHeader">Почта</p>
              {{ reference.mail }}
            </v-col>
            <v-col cols="12" sm="6" xs="12" md="2" lg="2">
              <p class="ReferenceOperatorListTableHeader">Телефон</p>
              {{ reference.phone }}
            </v-col>
            <v-col cols="12" sm="6" xs="12" md="2" lg="1">
              <p class="ReferenceOperatorListTableHeader">Вид справки</p>
              {{ getReferenceTypeName(reference.referenceType) }}
            </v-col>
            <v-col cols="12" sm="6" xs="12" md="2" lg="1">
              <p class="ReferenceOperatorListTableHeader">Заказано</p>
              {{ formatDate(reference.createdAt) }}
            </v-col>
            <v-col cols="12" sm="6" xs="12" md="2" lg="2">
              <p class="ReferenceOperatorListTableHeader">Статус</p>
              {{ getStatus(reference) }}
              <div v-if="isOrderTaken(reference) !== false">
                <p style="color: #e21a1a" class="pt-3">Забронирована оператором: {{ isOrderTaken(reference) }}</p>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content :color="getColor(reference)">
          <br />
          <ReferenceOperatorWork :order="reference" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ReferenceHelper from '@/helpers/reference/reference.helper';
import ViewHelper from '@/helpers/view.helper';
import ReferenceOperatorWork from '@/components/reference/ReferenceOperatorWork';
import moment from 'moment';
import { mapGetters } from 'vuex';
// import { mapActions } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      isShowCompleted: false,
      isShowCreated: false,
      isShowPaymentFalse: false,
      isShowPaymentTrue: true,
      isShowWaitingOperatorWork: true,
      isShowOperatorWorkDone: true,
      isShowWorkDone: false,
      isCancelReference: false,
      isWorkProgress: false,
      search: '',
      currentUser: JSON.parse(localStorage.getItem('user')).fio
    };
  },
  components: {
    ReferenceOperatorWork
  },
  computed: {
    filteredReferenceList() {
      let modifySearch = this.search.toLowerCase();
      let filtredArray = [];
      if (modifySearch !== '') {
        filtredArray = this.referenceList.filter(item => {
          return (
            item.fullName.toLowerCase().indexOf(modifySearch) !== -1 ||
            item.mail.indexOf(modifySearch) !== -1 ||
            item.phone.indexOf(modifySearch) !== -1 ||
            this.getReferenceTypeName(item.referenceType)
              .toLowerCase()
              .indexOf(modifySearch) !== -1 ||
            item.orderNumber.indexOf(modifySearch) !== -1
          );
        });
      } else {
        filtredArray = this.referenceList;
        if (this.isShowPaymentTrue === false) {
          filtredArray = filtredArray.filter(item => {
            return item.currentState !== 3;
          });
        }
        if (this.isShowWaitingOperatorWork === false) {
          filtredArray = filtredArray.filter(item => {
            return item.currentState !== 4;
          });
        }
        if (this.isShowOperatorWorkDone === false) {
          filtredArray = filtredArray.filter(item => {
            return item.currentState !== 5;
          });
        }
        if (this.isShowWorkDone === false) {
          filtredArray = filtredArray.filter(item => {
            return item.currentState !== 6;
          });
        }
        if (this.isCancelReference === false) {
          filtredArray = filtredArray.filter(item => {
            return item.currentState !== 100;
          });
        }
        if (this.isWorkProgress === false) {
          filtredArray = filtredArray.filter(item => {
            return this.isOrderTaken(item) === false || this.isOrderTaken(item) === this.currentUser;
          });
        }
      }
      return filtredArray;
    },
    ...mapGetters('reference', ['referenceList'])
  },
  methods: {
    getSeatName: ReferenceHelper.getSeatName,
    getCarName: ReferenceHelper.getCarName,
    getTariffName: ReferenceHelper.getTariffName,
    getStationName: ReferenceHelper.getStationName,
    getOrderStatus: ReferenceHelper.getOrderStatus,
    formatDate: ViewHelper.formatDate,
    formatDateOnly: ViewHelper.formatDateOnly,
    getReferenceTypeName: ReferenceHelper.getReferenceTypeName,
    getStatus(reference) {
      return ReferenceHelper.getOrderStatus(reference.currentState);
    },
    isOrderTaken(order) {
      if (!order || !order.reservedDate) {
        return false;
      }
      if (moment(order.reservedDate).isBefore(moment().subtract(10, 'm'))) {
        return false;
      }
      if (!order.reservedUser.fio || order.reservedUser.fio.length <= 0) {
        return '';
      }
      return order.reservedUser.fio;
    },
    getColor(item) {
      let color = '';
      if (item.currentState === 6) color = '#00FF7F20';
      else if (this.isOrderTaken(item) === false && item.currentState === 4) color = '#FFFF0020';
      else if (this.isOrderTaken(item).length > 0 && item.currentState === 4) color = '#1E90FF20';
      else if (item.currentState === 100) color = '#F1525220';
      return color;
    }
  }
};
</script>
<style scoped>
.ReferenceOperatorListMain {
  padding: 5px 5px 20px 5px;
  color: black;
}
.ReferenceOperatorListTableHeader {
  font-weight: bold;
}
.VipListTableFio {
  font-weight: bold;
}
</style>
