<template>
  <div class="Reference">
    <NavBar />
    <h2>Список заказов</h2>
    <div class="ReferenceOperatorMainframe">
      <div class="ReferenceUpBoard">
        <div v-if="!this.isReferenceLoading && !this.isReferenceError && this.referenceList && this.referenceList.length > 0">
          <div class="ReferenceProcessList">
            <div class="ReferenceProcessListNumber">
              {{ this.referenceList.length }}
            </div>
            <div class="VipProcessListText">
              {{ this.declOfNum(this.referenceList.length, ['заказ', 'заказа', 'заказов']) }}
              <v-spacer />
              всего
            </div>
          </div>
        </div>
        <div v-else>
          <v-chip>
            {{ this.loadProgress }}
          </v-chip>
        </div>
      </div>
      <div>
        <ReferenceOperatorList :referenceList="this.referenceList" />
      </div>
    </div>
    <NotificationContainer />
  </div>
</template>

<script>
import helper from '@/helpers/reference/reference.helper';
import ReferenceOperatorList from '@/components/reference/ReferenceOperatorList';
import NavBar from '@/components/NavBar';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import NotificationContainer from '@/components/notification/NotificationContainer.vue';

export default {
  data() {
    return {
      search: null,
      interval: null,
      countCurrentType: null
    };
  },
  components: {
    NotificationContainer,
    ReferenceOperatorList,
    NavBar
  },
  computed: {
    loadProgress() {
      if (this.isReferenceLoading) {
        return 'Загружаем список';
      } else if (this.isReferenceError) {
        return 'Ошибка загрузки списка: ' + this.referenceErrorMessage;
      } else if (!this.referenceList || this.referenceList.length <= 0) {
        return 'Список пуст';
      } else return 'Количество записей: ' + this.referenceList.length;
    },
    ...mapGetters('reference', ['referenceList']),
    ...mapGetters('reference', ['isReferenceLoading']),
    ...mapGetters('reference', ['isReferenceError']),
    ...mapGetters('reference', ['referenceErrorMessage']),
    ...mapGetters('reference', ['orderListTimer'])
  },
  mounted() {
    this.updateReferenceList();
    this.interval = setInterval(() => {
      let isNeedToUpdate = false;
      try {
        if (this.orderListTimer === undefined || this.orderListTimer === null) {
          isNeedToUpdate = true;
        } else {
          if (this.orderListTimer.isBefore(moment().subtract(10, 'm'))) {
            isNeedToUpdate = true;
          }
        }
      } catch (ex) {
        console.log(ex);
        isNeedToUpdate = true;
      }
      if (isNeedToUpdate) {
        this.updateReferenceList();
      }
    }, 60000);
  },
  beforeDestroy() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  },
  methods: {
    declOfNum: helper.declOfNum,
    updateReferenceList() {
      this.fetchReferenceList();
    },
    ...mapActions('reference', ['fetchReferenceList'])
  }
};
</script>

<style>
.Reference {
  //background-color: blue;
}
.ReferenceUpBoard {
  min-height: 50px;
}
.ReferenceOperatorMainframe {
  //background-color: burlywood;
}
.ReferenceSearch {
  border-radius: 5px;
  color: white;
  font-weight: 400;
  box-shadow: 0 2px 2px #8eaadb;
  border: solid 1px #2f5496;
  margin: 20px 0 10px 0;
  padding: 2px;
}
.text-black input {
  color: black !important;
}
.ReferenceOperatorList {
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
  font-weight: 500;
}
.ReferenceUploadButton {
  margin-left: auto;
}
.ReferenceProcessList {
  display: flex;
}
.ReferenceProcessListNumber {
  font-size: 50px;
  color: #e21a1a;
  padding-right: 10px;
  line-height: 1;
  font-weight: normal;
}
.ReferenceProcessListText {
  font-size: 14px;
  color: #8eaadb;
  margin-left: 16px;
}
</style>
